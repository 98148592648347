import React ,{ useState, useEffect ,useRef} from "react"   
import {
  HashRouter  as Router,
  Route,
  Routes ,
  NavLink,
  Link,
  useNavigate  
} from 'react-router-dom'
import Loadable from 'react-loadable'
import LoadingPage from '../LoadingPage/LoadingPage.js' 
import { createBrowserHistory } from "history";  


const AsyncHome = Loadable({
loader: () => import('../Landingpage.js'),
loading: LoadingPage,
delay: 5000
})
const AsyncSucess = Loadable({
loader: () => import('../success.js'),
loading: LoadingPage,
delay: 5000
}) 
const AsyncFailed = Loadable({
loader: () => import('../failed.js'),
loading: LoadingPage,
delay: 5000
}) 

const AsyncNotFound = Loadable({
loader: () => import('../notavailable.js'),
loading: LoadingPage,
delay: 5000
}) 
   
export default function Layout() {    
  const navigate = useNavigate();  
  const history = createBrowserHistory();   
  return (  
      <Routes> 
      <Route exact path="/" element={ <AsyncHome/> }  /> 
      <Route exact path="/sucess" element={ <AsyncSucess/> }   />
      <Route exact path="/failed" element={ <AsyncFailed/> } /> 
      <Route path="*" element={ <AsyncNotFound/> } /> 
      </Routes>  
  );
 
} 