import Layout from './components/Layout/Layout';  
import {useEffect} from 'react' 
import {
  HashRouter  as Router, 
} from 'react-router-dom' 
import { CookiesProvider } from 'react-cookie'; 
function App() {  
  return ( 
    <Router>
    <CookiesProvider>  
      <Layout></Layout> 
    </CookiesProvider>
    </Router>
  );
}

export default App;

